@font-face {
  font-family: 'Adamina';
  src: url(/assets/Adamina/Adamina-Regular.ttf);
}

@font-face {
  font-family: 'OpenSans';
  src: url(/assets/Open_Sans/static/OpenSans-Regular.ttf);
}

$primary_color: #a505f0;

.share-button {
   background-color: $primary_color;
   border-color: $primary_color;
    opacity: 0.9;
    &:hover {
      opacity: 1;
    }
}

h1 {
   text-decoration-color: $primary_color;
   text-decoration-line: underline;
}
